import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Snackbar, Alert as MuiAlert, Box, Grid, Divider } from '@mui/material';
import { styled } from '@mui/system';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactContainer = styled(Container)({
    marginTop: '50px',
    marginBottom: '50px',
});

const ContactInfo = styled(Box)({
    padding: '20px',
    '& a': {
        textDecoration: 'none',
        color: 'inherit',
    },
});

const ContactForm = styled(Box)({
    padding: '20px',
    '& .MuiTextField-root': {
        marginBottom: '20px',
    },
});

const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ name, email, phone, message });
        setSnackbarMessage('Message sent successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <RequireAuth>
            <ContactContainer>
                <Grid container spacing={4} alignItems="flex-start">
                    <Grid item xs={12} md={5}>
                        <ContactInfo>
                            <Typography variant="h2" gutterBottom style={{ color: '#F4A300', fontWeight: 'bold' }}>
                                CONTACT US
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                We partner with you to be more and achieve extraordinary results. Schedule your free consultation today!
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <a href="https://www.kaizenmind.rw/">kaizenmind.rw</a>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <a href="mailto:info@kaizenmind.rw">info@kaizenmind.rw</a>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <a href="tel:+250788396743">0788396743</a>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Kigali, Rwanda
                            </Typography>
                        </ContactInfo>
                    </Grid>
                    <Grid item md={1}>
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContactForm>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    required
                                />
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </form>
                        </ContactForm>
                    </Grid>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </ContactContainer>
        </RequireAuth>
    );
};

export default ContactPage;
