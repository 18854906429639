import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Button, CircularProgress, TextField,
    Snackbar, Alert as MuiAlert, Dialog, DialogActions,
    DialogContent, DialogTitle, Table, TableBody, TableCell,
    TableHead, TableRow, TableContainer, Paper
} from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SubscriptionStatus = ({ api }) => {
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [phoneNumber, setPhoneNumber] = useState('');  // Phone input state
    const [days, setDays] = useState(30);                // Number of days input state (will be replaced by tariff)
    const [price, setPrice] = useState(11900);           // Price input (will be replaced by tariff)

    const user_type = localStorage.getItem('user_type');

    useEffect(() => {
        // Fetch subscription status
        const fetchSubscriptionStatus = async () => {
            const token = localStorage.getItem('access_token');
            try {
                const response = await fetch(`${api}/subscriptions/me`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch subscription status');
                }

                const data = await response.json();
                setSubscription(data);
            } catch (error) {
                setSnackbarMessage('Error fetching subscription status.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubscription(null);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [api]);

    useEffect(() => {
        // Fetch active tariff details
        const fetchTariffDetails = async () => {
            const token = localStorage.getItem('access_token');
            try {
                const response = await fetch(`${api}/tariffs/active`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch tariff details');
                }

                const tariffData = await response.json();
                if (tariffData.length > 0) {
                    const activeTariff = tariffData[0];
                    setPrice(activeTariff.price);
                    setDays(30);
                }
            } catch (error) {
                setSnackbarMessage('Error fetching tariff details.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        fetchTariffDetails();
    }, [api]);

    const fetchPaymentHistory = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/payments/me`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch payment history');
            }

            const data = await response.json();
            setPayments(data);
        } catch (error) {
            setSnackbarMessage('Error fetching payment history.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    // Opens the payment dialog
    const handleOpenPaymentDialog = () => {
        setPaymentDialogOpen(true);
    };

    // Closes the payment dialog
    const handleClosePaymentDialog = () => {
        setPaymentDialogOpen(false);
    };

    // Opens the payment history dialog
    const handleOpenHistoryDialog = async () => {
        await fetchPaymentHistory();
        setHistoryDialogOpen(true);
    };

    // Closes the payment history dialog
    const handleCloseHistoryDialog = () => {
        setHistoryDialogOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const InitiatPayment = async () => {
        const token = localStorage.getItem('access_token');
        const payment_details = {
            phone_number: phoneNumber,
            user_ids: null,
        };

        try {
            const response = await fetch(`${api}/pay/subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payment_details)
            });
            if (!response.ok) throw new Error('failed to initiate payment');
            setSnackbarMessage('Please follow the created link to approve the payment');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('failed to initiate payment');
            setSnackbarSeverity('error');
        } finally {
            handleClosePaymentDialog();
        }
    };


    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Subscription Status
            </Typography>

            {loading ? (
                <CircularProgress />
            ) : subscription ? (
                <Box>
                    <Typography variant="body1">
                        Status: <strong style={{ color: subscription.is_active ? 'green' : 'red' }}>
                            {subscription.is_active ? 'Active' : 'Inactive'}
                        </strong>
                    </Typography>
                    <Typography variant="body1">
                        Start Date: {new Date(subscription.start_date).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1">
                        End Date: {subscription.end_date ? new Date(subscription.end_date).toLocaleDateString() : 'N/A'}
                    </Typography>

                    {user_type !== 'organization_user' && (
                        <div>
                            {/* Activate Subscription Button */}
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: '10px' }}
                                disabled={subscription?.is_active}
                                onClick={handleOpenPaymentDialog}  // Open payment dialog
                            >
                                Activate Subscription
                            </Button>

                            {/* Payment History Button */}
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ marginTop: '10px', marginLeft: '10px' }}
                                onClick={handleOpenHistoryDialog}
                            >
                                View Payment History
                            </Button>

                            {/* Payment Dialog */}
                            <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog} fullWidth maxWidth="sm">
                                <DialogTitle>Activate Subscription</DialogTitle>
                                <DialogContent>
                                    <Typography variant="body1" gutterBottom>
                                        Price: {price} RWF
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Number of days: {days} days
                                    </Typography>
                                    <TextField
                                        label="Phone Number"
                                        variant="outlined"
                                        fullWidth
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        sx={{ marginTop: '10px' }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={InitiatPayment} color="primary">
                                        Proceed to Payment
                                    </Button>
                                    <Button onClick={handleClosePaymentDialog} color="error">
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* Payment History Dialog */}
                            <Dialog open={historyDialogOpen} onClose={handleCloseHistoryDialog} fullWidth maxWidth="md">
                                <DialogTitle>Payment History</DialogTitle>
                                <DialogContent>
                                    {payments.length === 0 ? (
                                        <Typography>No payment history available.</Typography>
                                    ) : (
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Transaction ID</TableCell>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {payments.map((payment) => (
                                                        <TableRow key={payment.transaction_id}>
                                                            <TableCell>{payment.transaction_id}</TableCell>
                                                            <TableCell>{new Date(payment.date).toLocaleDateString()}</TableCell>
                                                            <TableCell>{payment.status}</TableCell>
                                                            <TableCell>{payment.amount}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseHistoryDialog} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )}
                </Box>
            ) : (
                <Typography variant="body1" color="error">
                    You do not have an active subscription.
                </Typography>
            )}

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SubscriptionStatus;
